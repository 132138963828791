import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import {
  SunIcon,
  MoonIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useTheme } from '../../contexts/ThemeContext';

const navigation = [
  { name: 'Início', href: '#' },
  { name: 'Serviços', href: '#' },
  { name: 'IA para Negócios', href: '#' },
  { name: 'Casos de Sucesso', href: '#' },
  { name: 'Contato', href: '#' },
];

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <Disclosure as="header" className="fixed w-full bg-white/90 dark:bg-[#111111]/90 backdrop-blur-sm z-50 border-b border-gray-100 dark:border-[#222222] transition-colors duration-300 shadow-sm">
      {({ open }) => (
        <>
          <nav className="container mx-auto px-4 py-4">
            <div className="flex justify-between items-center">
              <div className="text-2xl font-bold text-gray-900 dark:text-white">
                M<span className="text-neon-green">oxxy</span>
              </div>

              {/* Mobile menu button */}
              <Disclosure.Button className="md:hidden text-gray-700 dark:text-gray-300 hover:text-neon-green dark:hover:text-neon-green">
                {open ? (
                  <XMarkIcon className="h-6 w-6" />
                ) : (
                  <Bars3Icon className="h-6 w-6" />
                )}
              </Disclosure.Button>

              {/* Desktop menu */}
              <div className="hidden md:flex flex-1 justify-center items-center gap-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-700 dark:text-gray-300 hover:text-neon-green dark:hover:text-neon-green transition-colors duration-300"
                  >
                    {item.name}
                  </a>
                ))}
              </div>

              {/* Desktop buttons */}
              <div className="hidden md:flex items-center gap-4">
                <button
                  onClick={toggleDarkMode}
                  className="p-2 text-gray-700 dark:text-gray-300 hover:text-neon-green dark:hover:text-neon-green transition-colors duration-300"
                  aria-label={isDarkMode ? 'Ativar modo claro' : 'Ativar modo escuro'}
                >
                  {isDarkMode ? (
                    <SunIcon className="h-5 w-5" />
                  ) : (
                    <MoonIcon className="h-5 w-5" />
                  )}
                </button>
                <button 
                  onClick={() => navigate('/login')}
                  className="bg-neon-green hover:bg-neon-green/90 text-black px-6 py-2 rounded-full font-medium transition-all duration-300 transform hover:scale-105 shadow-sm"
                >
                  Painel do cliente
                </button>
              </div>
            </div>

            {/* Mobile menu panel */}
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="md:hidden mt-4">
                <div className="flex flex-col gap-4">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="text-gray-700 dark:text-gray-300 hover:text-neon-green dark:hover:text-neon-green transition-colors duration-300"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  <button 
                    onClick={() => navigate('/login')}
                    className="bg-neon-green hover:bg-neon-green/90 text-black px-6 py-2 rounded-full font-medium transition-all duration-300 transform hover:scale-105 shadow-sm"
                  >
                    Painel do cliente
                  </button>
                  <button
                    onClick={toggleDarkMode}
                    className="flex items-center gap-2 text-gray-700 dark:text-gray-300 hover:text-neon-green dark:hover:text-neon-green transition-colors duration-300"
                  >
                    {isDarkMode ? (
                      <SunIcon className="h-5 w-5" />
                    ) : (
                      <MoonIcon className="h-5 w-5" />
                    )}
                    <span>{isDarkMode ? 'Modo claro' : 'Modo escuro'}</span>
                  </button>
                </div>
              </Disclosure.Panel>
            </Transition>
          </nav>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
