import React, { useState, useCallback, useMemo } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import {
  ReactFlow,
  Controls,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  addEdge,
  ReactFlowProvider,
  Panel,
  Handle,
  Position,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { FiPlus, FiTrash2, FiMessageSquare, FiClock, FiMail, FiPhone } from 'react-icons/fi';
import toast from 'react-hot-toast';

// Tipos de nós disponíveis
const nodeCategories = [
  {
    id: 'triggers',
    label: 'Gatilhos',
    nodes: [
      { type: 'whatsappTrigger', label: 'Mensagem Recebida', icon: FiMessageSquare, color: 'blue' },
      { type: 'scheduleTrigger', label: 'Agendamento', icon: FiClock, color: 'purple' },
    ]
  },
  {
    id: 'actions',
    label: 'Ações',
    nodes: [
      { type: 'sendMessage', label: 'Enviar Mensagem', icon: FiMail, color: 'green' },
      { type: 'makeCall', label: 'Fazer Ligação', icon: FiPhone, color: 'orange' },
      { type: 'wait', label: 'Aguardar', icon: FiClock, color: 'gray' },
    ]
  }
];

// Componente base para todos os nós
const BaseNode = ({ data, type, isConnectable = true }: any) => {
  const nodeConfig = useMemo(() => {
    for (const category of nodeCategories) {
      const found = category.nodes.find(n => n.type === type);
      if (found) return found;
    }
    return null;
  }, [type]);

  if (!nodeConfig) return null;

  const Icon = nodeConfig.icon;

  return (
    <div className={`bg-white rounded-lg shadow-md border-2 border-${nodeConfig.color}-500 min-w-[200px]`}>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      
      <div className="p-3">
        <div className={`flex items-center gap-2 mb-3 text-${nodeConfig.color}-600`}>
          <Icon className="w-5 h-5" />
          <span className="font-medium">{nodeConfig.label}</span>
        </div>

        {type === 'wait' && (
          <div className="space-y-2">
            <label className="block text-sm text-gray-600">Tempo (segundos):</label>
            <input
              type="number"
              min="1"
              value={data.waitTime || 3}
              onChange={(e) => {
                data.onChange({ ...data, waitTime: parseInt(e.target.value) });
              }}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </div>
        )}

        {(type === 'sendMessage' || type === 'whatsappTrigger') && (
          <div className="space-y-2">
            <label className="block text-sm text-gray-600">Mensagem:</label>
            <textarea
              value={data.message || ''}
              onChange={(e) => {
                data.onChange({ ...data, message: e.target.value });
              }}
              rows={3}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-green-500"
            />
          </div>
        )}

        {type === 'makeCall' && (
          <div className="space-y-2">
            <label className="block text-sm text-gray-600">Número:</label>
            <input
              type="tel"
              value={data.phoneNumber || ''}
              onChange={(e) => {
                data.onChange({ ...data, phoneNumber: e.target.value });
              }}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-orange-500"
            />
          </div>
        )}

        {type === 'scheduleTrigger' && (
          <div className="space-y-2">
            <label className="block text-sm text-gray-600">Agendamento:</label>
            <input
              type="datetime-local"
              value={data.schedule || ''}
              onChange={(e) => {
                data.onChange({ ...data, schedule: e.target.value });
              }}
              className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-purple-500"
            />
          </div>
        )}
      </div>

      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

// Criar nodeTypes com todos os tipos de nós disponíveis
const nodeTypes = Object.fromEntries(
  nodeCategories.flatMap(category => 
    category.nodes.map(node => [
      node.type,
      (props: any) => <BaseNode {...props} type={node.type} />
    ])
  )
);

const FlowBuilder: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [showNodeMenu, setShowNodeMenu] = useState(false);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    []
  );

  const addNode = (type: string, label: string) => {
    const newNode = {
      id: `${type}-${Date.now()}`,
      type,
      position: { x: Math.random() * 300, y: Math.random() * 300 },
      data: {
        onChange: (newData: any) => {
          setNodes(nds => 
            nds.map(node => 
              node.id === newNode.id 
                ? { ...node, data: { ...node.data, ...newData } }
                : node
            )
          );
        }
      }
    };
    setNodes((nodes) => [...nodes, newNode]);
    setShowNodeMenu(false);
    toast.success(`Nó "${label}" adicionado!`);
  };

  const onDeleteNode = useCallback((nodeId: string) => {
    setNodes((nodes) => nodes.filter((node) => node.id !== nodeId));
    setEdges((edges) => edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
    toast.success('Nó removido!');
  }, []);

  return (
    <div style={{ width: '100%', height: 'calc(100vh - 4rem)' }} className="relative">
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          className={isDarkMode ? 'dark' : 'light'}
        >
          <Background />
          <Controls />
          
          {/* Menu flutuante de nós */}
          <Panel position="top-right" className="space-x-2">
            <button
              onClick={() => setShowNodeMenu(!showNodeMenu)}
              className="flex items-center gap-2 px-3 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              <FiPlus className="w-4 h-4" />
              Adicionar Nó
            </button>
            
            {showNodeMenu && (
              <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl border p-2 space-y-2">
                {nodeCategories.map((category) => (
                  <div key={category.id}>
                    <h3 className="text-sm font-medium text-gray-600 px-2 mb-1">{category.label}</h3>
                    {category.nodes.map((node) => {
                      const Icon = node.icon;
                      return (
                        <button
                          key={node.type}
                          onClick={() => addNode(node.type, node.label)}
                          className={`flex items-center gap-2 w-full px-2 py-1.5 text-sm text-gray-700 hover:bg-gray-100 rounded-md`}
                        >
                          <Icon className={`w-4 h-4 text-${node.color}-500`} />
                          {node.label}
                        </button>
                      );
                    })}
                  </div>
                ))}
              </div>
            )}

            <button
              onClick={() => {
                setNodes([]);
                setEdges([]);
                toast.success('Fluxo limpo!');
              }}
              className="flex items-center gap-2 px-3 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
            >
              <FiTrash2 className="w-4 h-4" />
              Limpar
            </button>
          </Panel>
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export default FlowBuilder;
