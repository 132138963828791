import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';

const DashboardLayout: React.FC = () => {
  return (
    <div className="flex min-h-screen bg-[#111111]">
      <Sidebar />
      <main className="flex-1 p-6 lg:ml-[280px] transition-all duration-300">
        <Outlet />
      </main>
    </div>
  );
};

export default DashboardLayout;
