import axios from 'axios';

// API principal do Moxxy
const mainApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.moxxy.com.br',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true
});

// API do serviço de WhatsApp
export const whatsappApi = axios.create({
  baseURL: 'https://evo.moxxy.com.br',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'apikey': '429683C4C977415CAAFCCE10F7D57E111997',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization, apikey'
  }
});

// Interceptors para a API principal
mainApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

mainApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Interceptors para a API do WhatsApp
whatsappApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('evoToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

whatsappApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Erro na chamada da API do WhatsApp:', error);
    if (error.response) {
      console.error('Detalhes do erro:', error.response.data);
    }
    return Promise.reject(error);
  }
);

// Exportar mainApi como default para manter compatibilidade
export default mainApi;
