import React from 'react';
import { useTheme } from '../contexts/ThemeContext';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  className = '',
  disabled,
  ...props
}) => {
  const { isDarkMode } = useTheme();

  const baseClasses = 'px-4 py-2 rounded-xl transition-all duration-200 font-medium';
  const variantClasses = variant === 'primary'
    ? `${
        disabled
          ? 'bg-gray-500 cursor-not-allowed'
          : isDarkMode
            ? 'bg-neon-green/20 text-neon-green hover:bg-neon-green/30'
            : 'bg-neon-green text-black hover:bg-neon-green/90'
      }`
    : `${
        disabled
          ? 'bg-gray-500 cursor-not-allowed'
          : isDarkMode
            ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
      }`;

  return (
    <button
      className={`${baseClasses} ${variantClasses} ${className}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
