import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ProtectedRoute from './components/ProtectedRoute';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Dashboard/Profile';
import Users from './pages/Dashboard/Users';
import Leads from './pages/Dashboard/Leads';
import Segments from './pages/Dashboard/Segments';
import WhatsApp from './pages/Dashboard/WhatsApp';
import Services from './pages/Dashboard/Services';
import FlowBuilder from './pages/Dashboard/FlowBuilder';
import Home from './pages/Home';
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <GoogleOAuthProvider clientId="30737466067-99o3i6238j12qt60pakmbj9iv19lggs2.apps.googleusercontent.com">
      <ThemeProvider>
        <AuthProvider>
          <Toaster position="top-right" />
          <Router>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              
              {/* Protected Dashboard Routes */}
              <Route path="/dashboard" element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
                <Route index element={<Dashboard />} />
                <Route path="profile" element={<Profile />} />
                <Route path="users" element={<Users />} />
                <Route path="services" element={<Services />} />
                <Route path="leads" element={<Leads />} />
                <Route path="segments" element={<Segments />} />
                <Route path="whatsapp" element={<WhatsApp />} />
                <Route path="flow-builder" element={<FlowBuilder />} />
              </Route>
            </Routes>
          </Router>
          <ToastContainer />
        </AuthProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;