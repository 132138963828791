import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiUser, FiMail, FiLock, FiEye, FiEyeOff, FiArrowLeft, FiMoon, FiSun } from 'react-icons/fi';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';

const Register: React.FC = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    senha: '',
    confirmarSenha: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (formData.senha !== formData.confirmarSenha) {
      setError('As senhas não coincidem');
      return;
    }

    if (formData.senha.length < 6) {
      setError('A senha deve ter pelo menos 6 caracteres');
      return;
    }

    setLoading(true);

    try {
      const { confirmarSenha, ...registrationData } = formData;
      await api.post('/auth/register', registrationData);

      const loginResponse = await api.post('/auth/login', {
        email: formData.email,
        senha: formData.senha
      });

      const { token, refreshToken, user } = loginResponse.data;
      login(token, user, refreshToken);
      navigate('/dashboard');
    } catch (err: any) {
      setError(err.response?.data?.error || 'Erro ao registrar. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-[#111111] flex items-center justify-center p-[30px] md:p-[60px]">
      <div className="w-full max-w-[1400px] min-h-[650px] md:min-h-[650px] bg-white dark:bg-[#1A1A1A] rounded-[25px] flex overflow-hidden">
        {/* Coluna 1 - Background */}
        <div className="hidden md:flex md:w-1/2 bg-neon-green/10 flex-col">
          <div className="flex-1 flex items-center justify-center">
            <div className="max-w-md">
              <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                Bem-vindo ao Moxxy
              </h1>
              <p className="text-lg text-gray-600 dark:text-gray-300">
                Crie sua conta gratuita e comece a gerenciar seus projetos de forma eficiente.
              </p>
            </div>
          </div>
        </div>

        {/* Coluna 2 - Formulário */}
        <div className="w-full md:w-1/2 flex flex-col relative">
          <div className="absolute top-6 left-6 right-6 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-neon-green dark:hover:text-neon-green transition-colors"
            >
              <FiArrowLeft className="h-5 w-5" />
              Voltar
            </button>

            <button
              onClick={toggleDarkMode}
              className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-neon-green dark:hover:text-neon-green transition-colors"
            >
              {isDarkMode ? (
                <FiSun className="h-5 w-5" />
              ) : (
                <FiMoon className="h-5 w-5" />
              )}
            </button>
          </div>
          
          <div className="flex-1 flex items-center justify-center px-6 pt-16">
            <div className="w-full max-w-[380px]">
              <div className="text-center">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  Crie sua conta gratuitamente
                </h2>
                <p className="mt-2 text-xs text-gray-600 dark:text-gray-400">
                  Ou{' '}
                  <button
                    type="button"
                    onClick={() => navigate('/login')}
                    className="font-medium text-neon-green hover:text-neon-green/90"
                  >
                    faça login se já tiver uma conta
                  </button>
                </p>
              </div>

              <form className="mt-6" onSubmit={handleSubmit}>
                {error && (
                  <div className="bg-red-500/10 border border-red-500/20 text-red-500 p-3 rounded-lg text-sm text-center mb-5">
                    {error}
                  </div>
                )}

                <div className="space-y-4 mb-6">
                  <div>
                    <label htmlFor="nome" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Nome completo
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center">
                        <FiUser className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        id="nome"
                        name="nome"
                        type="text"
                        required
                        disabled={loading}
                        className="block w-full rounded-md pl-8 pr-3 py-2 bg-gray-50/50 dark:bg-[#111111]/50 border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        placeholder="Digite seu nome"
                        value={formData.nome}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Email
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center">
                        <FiMail className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        disabled={loading}
                        className="block w-full rounded-md pl-8 pr-3 py-2 bg-gray-50/50 dark:bg-[#111111]/50 border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        placeholder="Digite seu email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="senha" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Senha
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center">
                        <FiLock className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        id="senha"
                        name="senha"
                        type={showPassword ? "text" : "password"}
                        required
                        disabled={loading}
                        className="block w-full rounded-md pl-8 pr-10 py-2 bg-gray-50/50 dark:bg-[#111111]/50 border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        placeholder="Digite sua senha"
                        value={formData.senha}
                        onChange={handleChange}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        disabled={loading}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 dark:text-gray-400 disabled:opacity-50"
                      >
                        {showPassword ? (
                          <FiEyeOff className="h-4 w-4" />
                        ) : (
                          <FiEye className="h-4 w-4" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="confirmarSenha" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Confirmar senha
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center">
                        <FiLock className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        id="confirmarSenha"
                        name="confirmarSenha"
                        type={showConfirmPassword ? "text" : "password"}
                        required
                        disabled={loading}
                        className="block w-full rounded-md pl-8 pr-10 py-2 bg-gray-50/50 dark:bg-[#111111]/50 border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        placeholder="Confirme sua senha"
                        value={formData.confirmarSenha}
                        onChange={handleChange}
                      />
                      <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        disabled={loading}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 dark:text-gray-400 disabled:opacity-50"
                      >
                        {showConfirmPassword ? (
                          <FiEyeOff className="h-4 w-4" />
                        ) : (
                          <FiEye className="h-4 w-4" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="group relative flex w-full justify-center rounded-md bg-neon-green px-3 py-2 text-sm font-semibold text-white hover:bg-neon-green/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-green disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {loading ? (
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    ) : (
                      'Criar conta'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
