import api, { whatsappApi } from './api';

interface CreateInstanceParams {
  instanceName: string;
  token: string;
  number?: string;
  qrcode?: boolean;
  integration?: string;
  reject_call?: boolean;
  msgCall?: string;
  groupsIgnore?: boolean;
  alwaysOnline?: boolean;
  readMessages?: boolean;
  readStatus?: boolean;
  syncFullHistory?: boolean;
  webhookUrl?: string;
  webhookByEvents?: boolean;
  webhookBase64?: boolean;
  webhookEvents?: string[];
}

interface Instance {
  id: string;
  instanceName: string;
  token: string;
  status: string;
}

interface SendMessageParams {
  instance: string;
  number: string;
  text: string;
}

export const whatsappService = {
  getAllInstances: async () => {
    try {
      const response = await whatsappApi.get('/instances');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar instâncias:', error);
      throw error;
    }
  },

  createInstance: async (params: CreateInstanceParams) => {
    try {
      const response = await whatsappApi.post('/instance/create', params);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar instância:', error);
      throw error;
    }
  },

  getInstanceStatus: async (instanceName: string) => {
    try {
      const response = await whatsappApi.get(`/instance/${instanceName}/status`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar status da instância:', error);
      throw error;
    }
  },

  connectInstance: async (instanceName: string) => {
    try {
      const response = await whatsappApi.post(`/instance/${instanceName}/connect`);
      return response.data;
    } catch (error) {
      console.error('Erro ao conectar instância:', error);
      throw error;
    }
  },

  disconnectInstance: async (instanceName: string) => {
    try {
      const response = await whatsappApi.post(`/instance/${instanceName}/disconnect`);
      return response.data;
    } catch (error) {
      console.error('Erro ao desconectar instância:', error);
      throw error;
    }
  },

  deleteInstance: async (instanceName: string) => {
    try {
      const response = await whatsappApi.delete(`/instance/${instanceName}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar instância:', error);
      throw error;
    }
  },

  sendMessage: async (params: SendMessageParams) => {
    try {
      const response = await whatsappApi.post(`/instance/${params.instance}/message/text`, {
        to: params.number,
        message: params.text
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      throw error;
    }
  }
};

export default whatsappService;