import React from 'react';
import {
  ChatBubbleLeftIcon,
  LightBulbIcon,
  CheckCircleIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';

const Process: React.FC = () => {
  const steps = [
    {
      icon: ChatBubbleLeftIcon,
      title: "Descoberta",
      description: "Uma conversa para mapear sua necessidade.",
      bgClass: "from-blue-500/5 to-neon-green/5"
    },
    {
      icon: LightBulbIcon,
      title: "Planejamento",
      description: "Nosso time cria o plano perfeito para você.",
      bgClass: "from-purple-500/5 to-neon-green/5"
    },
    {
      icon: CheckCircleIcon,
      title: "Execução",
      description: "Implementação e acompanhamento contínuo.",
      bgClass: "from-neon-green/5 to-neon-green/10"
    }
  ];

  return (
    <section className="relative isolate bg-white dark:bg-[#111111] px-6 py-24 transition-colors duration-300">
      {/* Primeiro gradiente - superior */}
      <div aria-hidden="true" className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
        <div
          style={{
            clipPath:
              'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-neon-green via-transparent to-[#111111] opacity-20 dark:opacity-30"
        />
      </div>
      
      {/* Segundo gradiente - central */}
      <div aria-hidden="true" className="absolute inset-x-0 top-1/2 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
        <div
          style={{
            clipPath:
              'polygon(0% 0%, 100% 0%, 100% 75%, 75% 100%, 25% 100%, 0% 75%)',
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] rotate-180 bg-gradient-to-bl from-neon-green via-transparent to-[#111111] opacity-25 dark:opacity-30"
        />
      </div>

      {/* Terceiro gradiente - inferior */}
      <div aria-hidden="true" className="absolute inset-x-0 bottom-0 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
        <div
          style={{
            clipPath:
              'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#111111] via-transparent to-neon-green opacity-20 dark:opacity-25"
        />
      </div>

      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-50/50 to-white dark:from-[#151515]/50 dark:to-[#111111]" />
      
      <div className="container relative mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 dark:text-white">
            Do insight à execução
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Um processo simplificado e eficiente para transformar suas ideias em realidade
          </p>
        </div>

        <div className="relative">
          {/* Connecting Lines */}
          <div className="hidden md:block absolute top-1/2 left-0 w-full h-0.5 bg-gradient-to-r from-neon-green/20 via-neon-green/30 to-neon-green/20 transform -translate-y-1/2" />
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative">
            {steps.map((step, index) => (
              <div key={index} className="relative group">
                {/* Connecting Arrow */}
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-4 text-neon-green/30 transform -translate-y-1/2 translate-x-full z-10">
                    <ArrowLongRightIcon className="w-8 h-8" />
                  </div>
                )}
                
                <div className="relative p-8 bg-white dark:bg-[#1A1A1A] rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green transition-all duration-500 group-hover:shadow-xl group-hover:shadow-neon-green/5 flex flex-col items-center text-center">
                  {/* Background Gradient */}
                  <div className={`absolute inset-0 rounded-2xl bg-gradient-to-br ${step.bgClass} opacity-0 group-hover:opacity-100 transition-opacity duration-500`} />
                  
                  {/* Content */}
                  <div className="relative">
                    <div className="text-neon-green mb-6 w-16 h-16 flex items-center justify-center rounded-xl bg-neon-green/10 group-hover:bg-neon-green/20 transition-all duration-300 transform group-hover:scale-110 group-hover:rotate-6 mx-auto">
                      <step.icon className="h-8 w-8" />
                    </div>
                    <span className="inline-block text-sm font-medium text-neon-green/70 mb-2">
                      Etapa {index + 1}
                    </span>
                    <h3 className="text-neon-green text-xl font-bold mb-4">
                      {step.title}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      {step.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
