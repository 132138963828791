import React from 'react';
import {
  CpuChipIcon,
  RocketLaunchIcon,
  ChartBarIcon,
  PaintBrushIcon,
  DevicePhoneMobileIcon,
  BeakerIcon,
} from '@heroicons/react/24/outline';

const Services: React.FC = () => {
  return (
    <section className="relative bg-white dark:bg-[#111111] px-6 py-24 transition-colors duration-300">
      <div className="container relative mx-auto px-4">
        {/* Header Section */}
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold mb-6 text-gray-900 dark:text-white bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-700 dark:from-white dark:to-gray-300">
            Nossos Serviços
          </h2>
          <p className="text-2xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-neon-green to-neon-green/70 mb-8">
            Inteligência Artificial Personalizada
          </p>
        </div>
        
        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-20">
          {[
            { icon: BeakerIcon, text: "Ferramentas de análise e previsão" },
            { icon: CpuChipIcon, text: "Assistentes virtuais e chatbots" },
            { icon: RocketLaunchIcon, text: "Processos automatizados e personalizados" },
            { icon: ChartBarIcon, text: "Soluções para otimizar produtividade" }
          ].map((feature, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <div className="text-neon-green mb-3 w-10 h-10 flex items-center justify-center">
                <feature.icon className="h-6 w-6" />
              </div>
              <p className="text-gray-600 dark:text-gray-400 text-sm font-medium">
                {feature.text}
              </p>
            </div>
          ))}
        </div>

        {/* Main Services */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: RocketLaunchIcon,
              title: "Automação e Inovação",
              description: "Eliminamos gargalos e transformamos processos manuais em fluxos de trabalho inteligentes."
            },
            {
              icon: ChartBarIcon,
              title: "Marketing de Alta Performance",
              description: "Estratégias que utilizam IA para identificar oportunidades e maximizar resultados."
            },
            {
              icon: PaintBrushIcon,
              title: "Design e UX",
              description: "Projetos visuais que unem estética e funcionalidade para gerar impacto."
            },
            {
              icon: DevicePhoneMobileIcon,
              title: "Desenvolvimento",
              description: "De sistemas simples a soluções complexas, criamos aplicativos sob medida."
            }
          ].map((service, index) => (
            <div key={index} className="group bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green transition-all duration-500 hover:shadow-xl hover:shadow-neon-green/10 flex flex-col items-center text-center">
              <div className="text-neon-green mb-6 w-14 h-14 flex items-center justify-center rounded-xl bg-neon-green/10 group-hover:bg-neon-green/20 transition-all duration-300 transform group-hover:scale-110 group-hover:rotate-6">
                <service.icon className="h-7 w-7" />
              </div>
              <h3 className="text-neon-green text-xl font-bold mb-4 group-hover:text-neon-green/90 transition-colors duration-300">
                {service.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
                {service.description}
              </p>
            </div>
          ))}
        </div>

        {/* Bottom Decoration */}
      </div>
    </section>
  );
};

export default Services;
