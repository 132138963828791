import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  FiHome,
  FiUser,
  FiUsers,
  FiGrid,
  FiTarget,
  FiLayers,
  FiMessageCircle,
  FiLogOut,
  FiMenu,
  FiX,
  FiChevronRight,
  FiGitBranch,
} from 'react-icons/fi';

interface SidebarItem {
  name: string;
  path: string;
  icon: React.ReactNode;
  adminOnly?: boolean;
}

interface User {
  photoURL?: string;
  nome?: string;
  nivel_acesso?: string;
}

const Sidebar: React.FC = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const sidebarItems: SidebarItem[] = [
    { name: 'Dashboard', path: '/dashboard', icon: <FiHome className="w-5 h-5" /> },
    { name: 'Perfil', path: '/dashboard/profile', icon: <FiUser className="w-5 h-5" /> },
    { name: 'Usuários', path: '/dashboard/users', icon: <FiUsers className="w-5 h-5" />, adminOnly: true },
    { name: 'Serviços', path: '/dashboard/services', icon: <FiGrid className="w-5 h-5" /> },
    { name: 'Leads', path: '/dashboard/leads', icon: <FiTarget className="w-5 h-5" /> },
    { name: 'Segmentos', path: '/dashboard/segments', icon: <FiLayers className="w-5 h-5" /> },
    { name: 'WhatsApp', path: '/dashboard/whatsapp', icon: <FiMessageCircle className="w-5 h-5" />, adminOnly: true },
    { name: 'Fluxos', path: '/dashboard/flow-builder', icon: <FiGitBranch className="w-5 h-5" />, adminOnly: true },
  ];

  const isActive = (path: string) => location.pathname === path;

  const toggleSidebar = () => setIsOpen(!isOpen);
  const toggleSection = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <>
      {/* Botão Mobile */}
      <button
        onClick={toggleSidebar}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-[#1E1E1E] text-white hover:bg-gray-800"
        aria-label={isOpen ? "Fechar menu" : "Abrir menu"}
      >
        {isOpen ? <FiX className="w-6 h-6" /> : <FiMenu className="w-6 h-6" />}
      </button>

      {/* Overlay Mobile */}
      {isOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={toggleSidebar}
          aria-hidden="true"
        />
      )}

      {/* Sidebar */}
      <aside 
        className={`fixed top-0 left-0 h-full w-[280px] bg-[#1A1D1F] flex flex-col shadow-xl transition-all duration-300 z-40
          ${isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
      >
        {/* Header */}
        <div className="p-4 border-b border-[#272B30]">
          <div className="flex items-center gap-3 px-2">
            <img 
              src="https://via.placeholder.com/40"
              alt="Profile" 
              className="w-10 h-10 rounded-full"
            />
            <div className="flex-1 min-w-0">
              <h2 className="text-sm font-medium text-white truncate">{user?.nome}</h2>
              <p className="text-xs text-[#6F767E]">
                {user?.nivel_acesso === '1' ? 'PRODUCT MANAGER' : 'USER'}
              </p>
            </div>
            <button onClick={toggleSidebar} className="lg:hidden text-[#6F767E] hover:text-white">
              <FiChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Navigation */}
        <div className="flex-1 overflow-y-auto">
          {/* Main Section */}
          <div className="py-2">
            <div className="px-4 py-2">
              <p className="text-xs font-medium text-[#6F767E] uppercase tracking-wider">MAIN</p>
            </div>
            <nav className="space-y-1 px-2">
              {sidebarItems.map((item) => {
                if (item.adminOnly && user?.nivel_acesso !== '1') return null;
                return (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`group flex items-center px-2 py-2 text-sm font-medium rounded-lg transition-all duration-200
                      ${isActive(item.path)
                        ? 'bg-[#272B30] text-white'
                        : 'text-[#6F767E] hover:bg-[#272B30] hover:text-white'
                      }`}
                    onClick={() => setIsOpen(false)}
                  >
                    {item.icon}
                    <span className="ml-3 flex-1">{item.name}</span>
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>

        {/* Footer */}
        <div className="p-4 border-t border-[#272B30]">
          <button
            onClick={() => { logout(); setIsOpen(false); }}
            className="flex items-center w-full px-2 py-2 text-sm font-medium text-[#6F767E] hover:text-[#FF6A55] rounded-lg transition-colors duration-200"
            aria-label="Sair da conta"
          >
            <FiLogOut className="w-5 h-5" />
            <span className="ml-3">Sair</span>
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
