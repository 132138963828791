import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiMail, FiLock, FiEye, FiEyeOff, FiArrowLeft, FiMoon, FiSun } from 'react-icons/fi';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { toast } from 'react-toastify';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(5);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockEndTime, setBlockEndTime] = useState<Date | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const from = (location.state as { from?: { pathname: string } })?.from?.pathname || '/dashboard';

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }

    // Verifica se existe bloqueio
    const blockedUntil = localStorage.getItem('loginBlockedUntil');
    if (blockedUntil) {
      const blockTime = new Date(blockedUntil);
      if (blockTime > new Date()) {
        setIsBlocked(true);
        setBlockEndTime(blockTime);
      } else {
        localStorage.removeItem('loginBlockedUntil');
      }
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isBlocked && blockEndTime) {
      timer = setInterval(() => {
        const now = new Date();
        if (blockEndTime <= now) {
          setIsBlocked(false);
          setBlockEndTime(null);
          localStorage.removeItem('loginBlockedUntil');
          setRemainingAttempts(5);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isBlocked, blockEndTime]);

  const handleBlock = () => {
    const blockDuration = 15 * 60 * 1000; // 15 minutos
    const endTime = new Date(Date.now() + blockDuration);
    setIsBlocked(true);
    setBlockEndTime(endTime);
    localStorage.setItem('loginBlockedUntil', endTime.toISOString());
  };

  const validateForm = () => {
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError('Por favor, insira um email válido');
      return false;
    }
    if (password.length < 8) {
      setError('A senha deve ter pelo menos 8 caracteres');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isBlocked) {
      toast.error('Aguarde o período de bloqueio terminar para tentar novamente');
      return;
    }

    if (!validateForm()) return;

    setError('');
    setLoading(true);

    try {
      const response = await api.post('/auth/login', {
        email,
        senha: password
      });

      const { token, user, refreshToken } = response.data;

      if (rememberMe) {
        localStorage.setItem('rememberedEmail', email);
      } else {
        localStorage.removeItem('rememberedEmail');
      }

      // Reset das tentativas após login bem sucedido
      setRemainingAttempts(5);
      localStorage.removeItem('loginBlockedUntil');

      login(token, user, refreshToken);
      navigate(from, { replace: true });

      toast.success('Login realizado com sucesso!');
    } catch (err: any) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Erro durante o login:', err);
      }

      setRemainingAttempts(prev => {
        const newAttempts = prev - 1;
        if (newAttempts <= 0) {
          handleBlock();
        }
        return newAttempts;
      });

      if (err.response) {
        const errorMessage = err.response.data.error || 'Erro ao fazer login. Verifique suas credenciais.';
        setError(errorMessage);
        toast.error(errorMessage);
      } else if (err.request) {
        const errorMessage = 'Erro de conexão com o servidor. Verifique sua conexão.';
        setError(errorMessage);
        toast.error(errorMessage);
      } else {
        const errorMessage = 'Erro ao fazer login: ' + err.message;
        setError(errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const getBlockTimeRemaining = () => {
    if (!blockEndTime) return '';
    const now = new Date();
    const diff = blockEndTime.getTime() - now.getTime();
    const minutes = Math.floor(diff / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // Função para lidar com o login do Google
  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      setLoading(true);
      setError('');
      
      // Verifica se temos uma credencial válida
      if (!credentialResponse?.credential) {
        throw new Error('Credencial do Google inválida');
      }

      const response = await api.post('/auth/google', {
        credential: credentialResponse.credential
      });

      const { token, refreshToken, user } = response.data;
      
      if (token && user) {
        login(token, user, refreshToken);
        toast.success('Login com Google realizado com sucesso!');
        navigate(from);
      } else {
        throw new Error('Dados de autenticação inválidos');
      }
    } catch (error: any) {
      console.error('Erro no login com Google:', error);
      let errorMessage = 'Erro ao fazer login com Google. Tente novamente.';
      
      if (error.response) {
        // Erro da API
        errorMessage = error.response.data?.error || errorMessage;
      } else if (error.request) {
        // Erro de rede
        errorMessage = 'Erro de conexão com o servidor. Verifique sua internet.';
      } else {
        // Outros erros
        errorMessage = error.message || errorMessage;
      }
      
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleError = () => {
    const errorMessage = 'Erro ao conectar com o Google. Tente novamente mais tarde.';
    setError(errorMessage);
    toast.error(errorMessage);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-[#111111] flex items-center justify-center p-[30px] md:p-[60px]">
      <div className="w-full max-w-[1400px] min-h-[650px] md:min-h-[650px] bg-white dark:bg-[#1A1A1A] rounded-[25px] flex overflow-hidden">
        {/* Coluna 1 - Background */}
        <div className="hidden md:flex md:w-1/2 bg-neon-green/10 flex-col">
          <div className="flex-1 flex items-center justify-center">
            <div className="max-w-md">
              <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
                Bem-vindo ao Moxxy
              </h1>
              <p className="text-lg text-gray-600 dark:text-gray-300">
                Sua plataforma completa para gerenciamento de projetos e tarefas.
              </p>
            </div>
          </div>
        </div>

        {/* Coluna 2 - Formulário */}
        <div className="w-full md:w-1/2 flex flex-col relative">
          <div className="absolute top-6 left-6 right-6 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-neon-green dark:hover:text-neon-green transition-colors"
            >
              <FiArrowLeft className="h-5 w-5" />
              Voltar
            </button>

            <button
              onClick={toggleDarkMode}
              className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-neon-green dark:hover:text-neon-green transition-colors"
            >
              {isDarkMode ? (
                <FiSun className="h-5 w-5" />
              ) : (
                <FiMoon className="h-5 w-5" />
              )}
            </button>
          </div>

          <div className="flex-1 flex items-center justify-center px-6 pt-16">
            <div className="w-full max-w-[380px]">
              <div className="text-center">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  Entre na sua conta
                </h2>
                <p className="mt-2 text-xs text-gray-600 dark:text-gray-400">
                  Ou{' '}
                  <button
                    type="button"
                    onClick={() => navigate('/register')}
                    className="font-medium text-neon-green hover:text-neon-green/90"
                  >
                    comece seu período de teste gratuito
                  </button>
                </p>
              </div>

              {/* Botão de login do Google */}
              <div className="mt-4">
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                    useOneTap={false}
                    theme={isDarkMode ? 'filled_black' : 'outline'}
                    size="large"
                    text="continue_with"
                    shape="rectangular"
                    locale="pt_BR"
                    context="signin"
                  />
                </GoogleOAuthProvider>
              </div>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300 dark:border-[#333333]" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white dark:bg-[#1A1A1A] px-2 text-gray-500 dark:text-gray-400">
                      Ou entre com email
                    </span>
                  </div>
                </div>
              </div>

              <form className="mt-6" onSubmit={handleSubmit}>
                {error && (
                  <div className="bg-red-500/10 border border-red-500/20 text-red-500 p-3 rounded-lg text-sm text-center mb-5">
                    {error}
                  </div>
                )}

                {isBlocked && (
                  <div className="bg-yellow-500/10 border border-yellow-500/20 text-yellow-500 p-3 rounded-lg text-sm text-center mb-5">
                    Conta temporariamente bloqueada. Tente novamente em {getBlockTimeRemaining()}
                  </div>
                )}

                {!isBlocked && remainingAttempts < 3 && (
                  <div className="bg-yellow-500/10 border border-yellow-500/20 text-yellow-500 p-3 rounded-lg text-sm text-center mb-5">
                    Atenção: Restam {remainingAttempts} tentativas antes do bloqueio temporário
                  </div>
                )}

                <div className="space-y-4 mb-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Email
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center">
                        <FiMail className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        disabled={isBlocked || loading}
                        autoComplete="email"
                        className="block w-full rounded-md pl-8 pr-3 py-2 bg-gray-50/50 dark:bg-[#111111]/50 border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        placeholder="Digite seu email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Senha
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center">
                        <FiLock className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        required
                        disabled={isBlocked || loading}
                        autoComplete="current-password"
                        className="block w-full rounded-md pl-8 pr-10 py-2 bg-gray-50/50 dark:bg-[#111111]/50 border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent text-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        placeholder="Digite sua senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        disabled={isBlocked || loading}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 dark:text-gray-400 disabled:opacity-50"
                      >
                        {showPassword ? (
                          <FiEyeOff className="h-5 w-5" />
                        ) : (
                          <FiEye className="h-5 w-5" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between mb-8">
                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      role="switch"
                      aria-checked={rememberMe}
                      disabled={isBlocked || loading}
                      onClick={() => setRememberMe(!rememberMe)}
                      className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-green disabled:opacity-50 ${
                        rememberMe ? 'bg-neon-green' : 'bg-gray-200 dark:bg-gray-700'
                      }`}
                    >
                      <span
                        className={`inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out ${
                          rememberMe ? 'translate-x-6' : 'translate-x-1'
                        }`}
                      />
                    </button>
                    <label htmlFor="remember-me" className="text-xs text-gray-700 dark:text-gray-300">
                      Lembrar-me
                    </label>
                  </div>

                  <div className="text-sm">
                    <button
                      type="button"
                      disabled={isBlocked || loading}
                      onClick={() => navigate('/forgot-password')}
                      className="font-medium text-sm text-neon-green hover:text-neon-green/90 disabled:opacity-50"
                    >
                      Esqueceu sua senha?
                    </button>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={isBlocked || loading}
                    className="group relative flex w-full justify-center rounded-md bg-neon-green px-3 py-2 text-sm font-semibold text-white hover:bg-neon-green/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-green disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {loading ? (
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    ) : (
                      'Entrar'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
